
export default {
    visjs: {
        htmlContainerName: 'visualization',
        zoomInPercentage: 1,
        timeLineConfig: {
            height: '600px',
            // width: '100%',
            maxHeight: '720px',
            // type: 'range',
            // align: 'left',
            showTooltips: false,
            zoomable: false,
            showCurrentTime: false,
            // showMinorLabels: true,
            // showMajorLabels: true,
            stack: true,
            margin: {
                item: 2,
            },
        },
    },
};
