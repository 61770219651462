
import config from './visjs/config.js';
import VisController from './visjs/VisController.js';

const visController = new VisController({
    containerName: config.visjs.htmlContainerName,
    timeLineConfig: config.visjs.timeLineConfig,
    //zoomInPercentage: config.visjs.zoomInPercentage,
});
visController.init();
