
import 'babel-polyfill';
import vis from 'vis';

import VisModel from './VisModel.js';

export default class VisController {

    constructor({
        containerName = 'visualization',
        timeLineConfig = {},
        zoomInPercentage = 0.5,
    } = {}) {
        this.htmlContainer = document.getElementById(containerName);
        //this.zoomOutButtonElement = document.getElementById('eventTimelineZoomOutButton');

        this.items = [];
        this.categories = [];
        this.zoomed = false;
        //this.zoomInPercentage = zoomInPercentage;

        this.timeLineConfig = timeLineConfig;

        // template to use
        const templateSource = document.getElementById('event-item-template').innerHTML;
        const template = Handlebars.compile(templateSource);
        this.timeLineConfig.template = template;
    }

    init() {
        if (this.htmlContainer && timelineEvents) {
            this.timelinePods = timelineEvents;
            this.initCategories();
            this.initVis();
            //this.initZoomOutButton();
        }
    }

    /*initZoomOutButton() {
        this.zoomOutButtonElement.addEventListener('click', () => {
            this.zoomed = false;
            this.updateZoomed();
            this.timeline.fit();
            this.timeline.redraw();
        });
    }*/

    initCategories() {
        this.categories = document.getElementsByClassName('cv-event-category__radio');
        for (const category of this.categories) {
            category.addEventListener('change', () => {
                this.updateVis();
            });
        }
    }

    itemSelectedEventHandler(properties) {

        const item = this.getItemById(properties.items[0]);

        //const startDate = item.start;
        //startDate.setDate(startDate.getDate() - 30);

        this.zoomed = !!item;
        this.updateZoomed();

        /*if (this.zoomed === false) {
            this.zoomed = true;
            this.updateZoomed();
        }*/
        /*else {
            this.updateZoomed();
        }*/

        /*if (this.zoomed === false) {
            this.zoomed = true;

            this.updateZoomed();

            //const endDate = item.end || null;
            //if (endDate) endDate.setDate(endDate.getDate() + 30);
            //const endDate = startDate.setDate(startDate.getDate() + 720);

            let moment = vis.moment;
            let endDate = moment(startDate).add(3, 'years');

            this.timeline.setWindow(startDate, endDate, () => {
                this.timeline.redraw();
            });
        }
        else {

            this.timeline.setWindow(startDate, null, {animate: true}, () => {
                //this.timeline.redraw();
            });
        }*/
    }

    updateZoomed() {
        if (this.zoomed === true) {
            this.htmlContainer.classList.add('zoomed');
            //this.zoomOutButtonElement.classList.add('zoomed');
        } else {
            this.htmlContainer.classList.remove('zoomed');
            //this.zoomOutButtonElement.classList.remove('zoomed');
        }
    }

    updateVis() {
        const categoryIDs = Array.from(this.categories)
            .filter(category => category.checked)
            .map(category => category.value);


        const timelineItems = this.items.filter(item => item.hasOneOfCategoryIDs(categoryIDs));
        //const timelineItems = this.items;
        const dataSet = new vis.DataSet(timelineItems);


        if (!this.timeline) {
            this.timeline = new vis.Timeline(this.htmlContainer, dataSet, this.timeLineConfig);
            this.timeline.on('select', this.itemSelectedEventHandler.bind(this));
        } else {
            this.timeline.setItems(dataSet);
        }

        const range = this.timeline.getItemRange();
        this.timeline.setOptions({
            max: range.max.setDate(range.max.getDate() + 1480),
            min: range.min.setDate(range.min.getDate() - 720),
        });

        this.zoomed = false;
        this.updateZoomed();
        this.timeline.fit();
    }

    initVis() {
        if (this.timelinePods.length > 0) {
            this.items = this.timelinePods.map(timelinePod => {

                const date1 = new Date(timelinePod.start);
                const date2 = new Date(timelinePod.end);
                const diffTime = Math.abs(date2 - date1);
                const diffYears = Math.round(diffTime / (1000 * 60 * 60 * 24 * 365));

                let endDate = new Date(timelinePod.end);
                if (diffYears < 5) {
                    endDate = new Date(date1.setFullYear(date1.getFullYear() + 5));
                }

                return new VisModel({
                    id: timelinePod.id,
                    title: timelinePod.title,
                    content: timelinePod.content,
                    start: new Date(timelinePod.start),
                    displayedEnd: timelinePod.end ? new Date(timelinePod.end) : undefined,
                    end: timelinePod.end ? endDate : undefined,
                    category: timelinePod.category.term_id,
                    color: timelinePod.color,
                });
            });

            this.updateVis();
        }
    }

    getItemById(id) {
        return this.items.find(item => item.id === id);
    }

}
