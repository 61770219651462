

export default class VisModel {

    constructor({
        id,
        title,
        content,
        start,
        end,
        displayedEnd,
        displayedEndYear,
        category,
        color,
    }) {
        this.id = id;
        this.title = title;
        this.content = content;
        this.start = start;
        this.end = end;
        this.displayedEnd = displayedEnd;
        this.category = category;
        this.color = color;

        this.startYear = this.start.getFullYear();
        this.endYear = this.end ? this.end.getFullYear() : undefined;
        this.displayedEndYear = this.displayedEnd.getFullYear();
    }

    hasOneOfCategoryIDs(ids) {
        return ids.some(id => this.hasCategoryWithId(id));
    }

    hasCategoryWithId(categoryId) {
        return this.category === categoryId;
    }


}
